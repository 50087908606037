import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import { YouTubeVideo } from "../../services/helperFunctions";
import "../../static/style/pageStyle/youtube-video.scss"
import "../../static/style/globalCss/platform-page.scss"
import GetStartedModal from "../../components/getStaredModal";
import BookDemo from "../../components/book-demo";

const Post = props => {
  //   const { tabs } = FetchedResourcePageData();
  let videoData = YouTubeVideo().video;
  const [runningVideo, setRunningVideo] = useState(videoData[0]);
  const [listVideo, setListVideo] = useState(videoData);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const lastPart = currentPath.substring(currentPath.lastIndexOf('/') + 1);
    const filteredVideo = videoData.find(vid => vid.slug === lastPart);
    setRunningVideo(filteredVideo);

    const updatedVideoData = videoData.filter(video => video.slug !== lastPart);
    setListVideo(updatedVideoData);
  }, []);

  const handleVideoClick = (slug, heading) => {    
    // Find the video by its slug
    let videoData = YouTubeVideo().video;
    const selectedVideo = videoData.find(video => video.slug == slug);
    // const updatedVideoData = videoData.filter(video => video.slug != runningVideo.slug);
    // setListVideo(updatedVideoData)

    // Set the running video to the selected one
    setRunningVideo(selectedVideo);
    window.history.pushState({}, "", `/Videos/${slug}`);
    
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // for smooth scrolling
    });
  };
  

  useEffect(() => {
    let videoData = YouTubeVideo().video;
    const updatedVideoData = videoData.filter(video => video.slug != runningVideo.slug);
    setListVideo(updatedVideoData)
  }, [runningVideo]);

  return (
    <>
      <Helmet htmlAttributes={{
        lang: 'en'
      }}>
        <meta property="og:image" content="https://devwebbk.lumiq.ai/uploads/pic1_9f9d4d1857.png" />
        <meta property="og:description" content="Brief description of your blog post." />
        <meta property="og:type" content="website" />

      </Helmet>
      <div className="youtube-video platform-v3">
        <div className="page-content">
          <div className="main-container">
            <div className="page-wrapper">
            <div className="btn-area">
              <BookDemo
                content={"Go Back"}
                routeTo="/benefits"
              />
            </div>
            </div>
            <div className="page-wrapper whole-content">
              <div className="left-video">
                <div className="iframe-container">
                  <iframe
                    src={`${runningVideo.src}${runningVideo.src.includes('?') ? '&' : '?'}autoplay=1&mute=0&rel=0`}
                    title={runningVideo.title}
                    frameborder={runningVideo.frameborder}
                    allow={runningVideo.allow}
                    allowfullscreen={runningVideo.allowfullscreen}
                  ></iframe>
                </div>
                <h3 className="heading">{runningVideo?.heading}</h3>
                <p className="content">{runningVideo.fullDescription}</p>
              </div>
              <div className="right-list">
                {listVideo.map((data, index) => (
                  <div key={index}>
                    <div className="iframe-container">
                      <iframe
                        src={data.src}
                        title={data.title}
                        frameborder={data.frameborder}
                        allow={data.allow}
                        allowfullscreen={data.allowfullscreen}
                      ></iframe>
                    </div>
                    <p className="title">{data.heading}</p>
                    <p className="description"> {data.description}</p>
                    <div className="video-cover" onClick={() => handleVideoClick(data.slug, data.heading)}></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Post
